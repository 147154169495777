// Common JavaScript code across your application goes here.
$(function(){
  $('.delete-btn').submit(function() {
    var answer = confirm('Are you sure?');
    return answer;
  });

  window.setTimeout((function(){
    $('.notice, .success').slideUp(function(){
      $(this).remove();
    });
  }),1000);

  if ( $().dragsort ) {
    $('.favourites').dragsort({
      dragSelector: 'li',
      dragEnd: function() {
        var list = $(this).closest('.favourites').children();
        var order = [];
        list.each(function(){
          var id = $(this).attr('id').replace('product-','');
          order.push(id + ':' + $(this).attr('data-itemidx'));
        })
        $.post('/account/favourites/', { 'order': order.join(',') },function(data){
          var n = $('<div class="order-message"></div>').css({
            position: 'absolute',
            left: 0,
            top: $(window).scrollTop(),
            'z-index': 10000
          });
          if ( data == 'success' ) {
            n.text('Successfully ordered favourites').addClass('success');
          } else {
            n.text('Error ordering favourites').addClass('error');
          }
          $('#main > .content').prepend(n);
          window.setTimeout(function(){
            n.slideUp(function(){$(this).remove()});
          },500);
        });
      },
      placeHolderTemplate: '<li></li>'
    })
  }
  /*$('.accordion').accordion({
    header: '.heading',
    autoHeight: false
  })*/
});


jQuery.postd = function (queue, time, url, data, callback, type) {
	return jQuery.ajaxd(queue, time, {
		type: "POST",
		url: url,
		data: data,
		success: callback,
		dataType: type
	});
}

jQuery.ajaxd = function(queue, time, options) {
  if (typeof jQuery.ajaxd.q == "undefined") jQuery.ajaxd.q = {};
  if (jQuery.ajaxd.q[queue] || typeof options == "boolean") {
    q = jQuery.ajaxd.q[queue];
    if (q.request) {
      q.request.abort();
    }
    clearTimeout(q.timeout);
  }
  if (typeof options != "boolean") {
    jQuery.ajaxd.q[queue] = {}
    jQuery.ajaxd.q[queue].timeout = setTimeout(function() {
      jQuery.ajaxd.q[queue].request = jQuery.ajax(options);
    },time);
  }
}
