updateSidebar = function(ev) {
  $('#sidebar #cart').load('/cart/sidebar?' + new Date().getTime(), null, function(){
    $('form.remove').ajaxForm(updateSidebar);
    $('.cart form.empty').submit(function(ev){
      if (window.confirm('Are you sure?')) {
        $('.cart form.empty').ajaxSubmit(updateSidebar);
      }
      return false;
    });
  });
}

loadSidebar = function() {
  $("form.add").on("ajax:success", function() {
    updateSidebar();
  });
  updateSidebar();
}

$(function(){
  loadSidebar();
})

document.addEventListener("turbolinks:load", function() {
  loadSidebar();
})
